import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { CHANGE_PASSWORD_POST, EMAIL_POST, RESET_PASSWORD_POST, STATUS_POST } from '../interfaces/common';
import { AlertService } from './alert.service';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  /* 通信先 */
  url = environment.apiUrl;

  /**
   * Constructor
   *
   * @param { HttpClient } httpClient
   * @param { AlertService } alertService
   */
  constructor(
    private httpClient: HttpClient,
    private alertService: AlertService
  ) { }

  /**
   * パスワードを変更
   *
   * 戻り：COMMON_RESPONSE
   * 対象：WEB ○ / APP ○
   *
   * @param { CHANGE_PASSWORD_POST } postdata ポストデータ
   * @returns { Observable<any> }             結果
   * @throws { any }                          エラーメッセージ
   */
  changePassword(postdata: CHANGE_PASSWORD_POST): Observable<any> {
    return this.httpClient.post<any>(`${this.url}/user/current/change_password`, postdata)
      .pipe(
        catchError(
          error => {
            this.alertService.showAlert(error);
            throw error;
          }
        )
      );
  }

  /**
   * パスワードリセットのリクエスト
   *
   * 戻り：COMMON_RESPONSE
   * 対象：WEB ○ / APP ○
   *
   * @param { RESET_PASSWORD_POST } postdata  ポストデータ
   * @returns { Observable<any> }             結果
   * @throws { any }                          エラーメッセージ
   */
  forgotPassword(postdata: EMAIL_POST): Observable<any> {
    return this.httpClient.post<any>(`${this.url}/user/reset_password/request`, postdata)
      .pipe(
        catchError(
          error => {
            this.alertService.showAlert(error);
            throw error;
          }
        )
      );
  }

  /**
   * パスワードをリセット
   *
   * 戻り：COMMON_RESPONSE
   * 対象：WEB ○ / APP ○
   *
   * @param { RESET_PASSWORD_POST } postdata  ポストデータ
   * @returns { Observable<any> }             結果
   * @throws { any }                          エラーメッセージ
   */
  resetPassword(postdata: RESET_PASSWORD_POST): Observable<any> {
    return this.httpClient.post<any>(`${this.url}/user/reset_password/reset`, postdata)
      .pipe(
        catchError(
          error => {
            this.alertService.showAlert(error);
            throw error;
          }
        )
      );
  }

  /**
   * ログイン中ユーザー情報取得
   *
   * 戻り：USER_DATA
   * 対象：WEB ○ / APP ○
   *
   * @returns { Observable<any> }     結果
   * @throws { any }                  エラーメッセージ
   */
  getCurrentUser(): Observable<any> {
    return this.httpClient.get<any>(`${this.url}/user/current`)
      .pipe(
        catchError(
          error => {
            this.alertService.showAlert(error);
            throw error;
          }
        )
      );
  }

  /**
   * ステータスを変更する
   *
   * 戻り：COMMON_RESPONSE
   * 対象：WEB ○ / APP ○
   *
   * @param { STATUS_POST } postdata  ポストデータ
   * @returns { Observable<any> }     結果
   */
  changeStatus(postdata: STATUS_POST): Observable<any> {
    return this.httpClient.post<any>(`${this.url}/status/change`, postdata)
      .pipe(
        catchError(
          error => {
            this.alertService.showAlert(error);
            throw error;
          }
        )
      );
  }
}