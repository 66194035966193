import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertController, LoadingController, ModalController, ToastController } from '@ionic/angular';

import { SystemConst } from '../../../app-constant';
import { COMMON_RESPONSE } from '../../../interfaces/common';
import { NEW_WORKER_RESPONSE, EDIT_WORKER_POST } from '../../../interfaces/worker';
import { WorkerService } from '../../../providers/worker.service';
import { CustomValidator } from 'src/app/validators/custom-validator';

@Component({
  selector: 'app-modal-worker',
  templateUrl: './modal-worker.page.html',
  styleUrls: ['./modal-worker.page.scss'],
})
export class ModalWorkerPage implements OnInit {

  /* パラメータ */
  @Input() workerID: number;          // 作業者ID
  @Input() worker_name: string;       // 作業者名
  @Input() worker_name_kana: string;  // 作業者名カナ
  @Input() phone_number: string;      // 電話番号
  @Input() email: string;             // メール
  @Input() is_manager: boolean;       // 管理者権限
  password: string;                   // パスワード

  /* 画面制御 */
  newForm: FormGroup;
  editForm: FormGroup;
  isSubmitted: boolean = false;

  /**
   * Constructor
   *
   * @param { FormBuilder } formBuilder
   * @param { AlertController } alertController
   * @param { LoadingController } loadingController
   * @param { ModalController } modalController
   * @param { ToastController } toastController
   * @param { WorkerService } workerService
   */
  constructor(
    private formBuilder: FormBuilder,
    private alertController: AlertController,
    private loadingController: LoadingController,
    private modalController: ModalController,
    private toastController: ToastController,
    private workerService: WorkerService
  ) { }

  /**
   * ngOnInit
   * フォームの設定
   *
   */
  ngOnInit() {

    // 新規登録時のフォーム
    this.newForm = this.formBuilder.group({
      worker_name: [this.worker_name, Validators.compose([Validators.maxLength(200), Validators.required])],
      worker_name_kana: [this.worker_name_kana, Validators.compose([Validators.maxLength(200), Validators.required, CustomValidator.katakana])],
      phone_number: [this.phone_number, Validators.compose([Validators.minLength(10), Validators.maxLength(15), Validators.pattern('^[0-9()-]+$')])],
      password: [this.password, Validators.compose([Validators.minLength(8), Validators.maxLength(128), Validators.required])],
      email: [this.email, Validators.compose([Validators.email, Validators.required])],
      is_manager: [this.is_manager,]
    });

    // 編集時のフォーム
    this.editForm = this.formBuilder.group({
      worker_name: [this.worker_name, Validators.compose([Validators.maxLength(200), Validators.required])],
      worker_name_kana: [this.worker_name_kana, Validators.compose([Validators.maxLength(200), Validators.required, CustomValidator.katakana])],
      phone_number: [this.phone_number, Validators.compose([Validators.minLength(10), Validators.maxLength(15), Validators.pattern('^[0-9()-]+$')])],
      password: [this.password, Validators.compose([Validators.minLength(8), Validators.maxLength(128)])],
      email: [this.email, Validators.compose([Validators.email, Validators.required])],
      is_manager: [this.is_manager,]
    });
  }

  /**
   * エラーフィールドを返却
   *
   */
  get errorControl() {
    if (this.workerID) {
      return this.editForm.controls;
    } else {
      return this.newForm.controls;
    }
  }

  /**
   * 登録ボタンを押された時の処理
   *
   * 入力エラーがある場合はそのままreturn
   * アラートを表示後、新規登録・編集をID有無で判別して、
   * 該当の機能を呼び出し
   *
   */
  async submitForm() {

    this.isSubmitted = true;

    // 新規登録の時
    if (!this.workerID && !this.newForm.valid) {
      return false;
    }

    // 編集の時
    if (this.workerID && !this.editForm.valid) {
      return false;
    }

    // アラート表示
    const alert = await this.alertController.create({
      header: this.workerID ? '編集' : '　新規登録',
      message: this.workerID ? '作業者の情報を変更します' : '作業者を新規に登録します',
      buttons: [
        {
          text: 'キャンセル',
          role: 'cancel',
          cssClass: 'secondary'
        }, {
          text: 'Ok',
          handler: () => {
            if (this.workerID) {
              this.editWorker();
            } else {
              this.newWorker();
            }
          }
        }
      ]
    });
    await alert.present();
  }

  /**
   * 作業者を追加する
   *
   */
  async newWorker() {

    // くるくる
    let loading = await this.loadingController.create();
    await loading.present();

    this.workerService.newWorker(this.newForm.value).subscribe(
      res => {
        const response = res as NEW_WORKER_RESPONSE;
        loading.dismiss();
        if (response.returnCode === SystemConst.ReturnCode.SUCCESS) {
          this.showToast("登録しました");
          this.modalController.dismiss({ workerID: response.worker_id });
        }
      },
      error => {
        loading.dismiss();
        console.log("ログ：error：newWorker", error);
      }
    );
  }

  /**
   * 作業者を編集する
   *
   */
  async editWorker() {

    // パスワードの変更がない時はキーを削除
    const tmp: EDIT_WORKER_POST = this.editForm.value;
    if (!tmp.password || tmp.password === '') {
      delete tmp['password'];
    }

    // くるくる
    let loading = await this.loadingController.create();
    await loading.present();

    this.workerService.editWorker(this.workerID, tmp).subscribe(
      res => {
        const response = res as NEW_WORKER_RESPONSE;
        loading.dismiss();
        if (response.returnCode === SystemConst.ReturnCode.SUCCESS) {
          this.showToast("変更しました");
          this.modalController.dismiss({ workerID: this.workerID });
        }
      },
      error => {
        loading.dismiss();
        console.log("ログ：error：editWorker", error);
      }
    );
  }

  /**
   * 登録完了のトーストを表示
   *
   */
  async showToast(msg: string) {
    const toast = await this.toastController.create({
      color: 'tertiary',
      position: 'middle',
      message: msg,
      duration: 2000
    });
    toast.present();
  }

  /**
   * 画面を閉じる
   *
   */
  close() {
    this.modalController.dismiss();
  }
}
