import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { NEW_WORK_POST, EDIT_WORK_POST, REQUEST_POST, GEO_POST } from '../interfaces/task';
import { AlertService } from './alert.service';

@Injectable({
  providedIn: 'root'
})
export class WorkService {

  /* 通信先 */
  url = environment.apiUrl;

  /**
   * Constructor
   *
   * @param { HttpClient } httpClient
   * @param { AlertService } alertService
   */
  constructor(
    private httpClient: HttpClient,
    private alertService: AlertService
  ) { }

  /**
   * サーバーから作業一覧を取得
   *
   * ソート：id, status, assigned_worker__user__name, assigned_worker__user__name_kana, assigned_org__org_name, assigned_org__org_name_kana, work_date
   * 検索：assigned_worker__user__name, assigned_worker__user__name_kana, assigned_org__org_name, assigned_org__org_name_kana, work_address__postal_code, work_address__prefecture, work_address__address1, work_address__address2
   * 戻り：WORK_RESPONSE
   * 対象：WEB ○ / APP ○
   *
   * @param { number } p            ページ番号(1〜)
   * @param { number } s            1ページあたりの取得数
   * @param { string } ordering     表示順
   * @param { string } search       検索文字列
   * @param { string } status       ステータス（複数の時はカンマ区切り）
   * @returns { Observable<any> }   結果
   * @throws { any }                エラーメッセージ
   */
  getWorkList(p?: number, s?: number, ordering?: string, search?: string, status?: string): Observable<any> {

    let params = new HttpParams();

    // ページ番号(1〜)
    if (p) {
      params = params.set('p', p.toString());
    }

    // 1ページあたりの取得数
    if (s) {
      params = params.set('s', s.toString());
    }

    // 並び替えカラム名
    if (ordering) {
      params = params.set('ordering', ordering);
    }

    // 検索条件
    if (search) {
      params = params.set('search', search);
    }

    // ステータス
    if (status) {
      params = params.set('status', status);
    }

    return this.httpClient.get(`${this.url}/workrequest/`, { params })
      .pipe(
        catchError(
          error => {
            this.alertService.showAlert(error);
            throw error;
          }

        )
      );
  }

  /**
   * サーバーから同一住所に基づく作業一覧取得
   *
   * ソート：id, status, assigned_worker__user__name, assigned_worker__user__name_kana, assigned_org__org_name, assigned_org__org_name_kana, work_date
   * 検索：assigned_worker__user__name, assigned_worker__user__name_kana, assigned_org__org_name, assigned_org__org_name_kana, work_address__postal_code, work_address__prefecture, work_address__address1, work_address__address2
   * 戻り：WORK_RESPONSE
   * 対象：WEB ○ / APP ○
   *
   * @param { number } workID       作業ID
   * @param { number } p            ページ番号(1〜)
   * @param { number } s            1ページあたりの取得数
   * @param { string } ordering     表示順
   * @param { string } search       検索文字列
   * @param { string } status       ステータス（複数の時はカンマ区切り)
   * @returns { Observable<any> }   結果
   * @throws { any }                エラーメッセージ
   */
  getPastWorkList(workID: number, p?: number, s?: number, ordering?: string, search?: string, status?: string): Observable<any> {

    let params = new HttpParams();

    // ページ番号(1〜)
    if (p) {
      params = params.set('p', p.toString());
    }

    // 1ページあたりの取得数
    if (s) {
      params = params.set('s', s.toString());
    }

    // 並び替えカラム名
    if (ordering) {
      params = params.set('ordering', ordering);
    }

    // 検索条件
    if (search) {
      params = params.set('search', search);
    }

    // ステータス
    if (status) {
      params = params.set('status', status);
    }

    return this.httpClient.get(`${this.url}/workrequest/${workID}/works_for_address`, { params })
      .pipe(
        catchError(
          error => {
            this.alertService.showAlert(error);
            throw error;
          }
        )
      );
  }

  /**
   * 作業情報詳細を取得
   *
   * 戻り：WORK_DETAILS
   * 対象：WEB ○ / APP ○
   *
   * @param { number } workID     作業ID
   * @returns { Observable<any> } 結果
   * @throws { any }              エラーメッセージ
   */
  getWorkDetail(workID: number): Observable<any> {
    return this.httpClient.get<any>(`${this.url}/workrequest/${workID}`)
      .pipe(
        catchError(
          error => {
            this.alertService.showAlert(error);
            throw error;
          }
        )
      );
  }

  /**
   * 作業を新規作成する
   *
   * 戻り：NEW_WORK_RESPONSE
   * 対象：WEB ○ / APP ×
   *
   * @param { NEW_WORK_POST } postdata        ポストデータ
   * @returns { Observable<any> }             結果
   * @throws { any }                          エラーメッセージ
   */
  newWork(postdata: NEW_WORK_POST): Observable<any> {
    return this.httpClient.post<any>(`${this.url}/workrequest/new`, postdata)
      .pipe(
        catchError(
          error => {
            this.alertService.showAlert(error);
            throw error;
          }
        )
      );
  }

  /**
   * 作業を編集する
   *
   * 戻り：REQUEST_RESPONSE
   * 対象：WEB ○ / APP ×
   *
   * @param { number } workID               作業ID
   * @param { EDIT_WORK_POST } postdata     ポストデータ
   * @returns { Observable<any> }           結果
   * @throws { any }                        エラーメッセージ
   */
  editWork(workID: number, postdata: EDIT_WORK_POST): Observable<any> {
    return this.httpClient.post<any>(`${this.url}/workrequest/${workID}/edit`, postdata)
      .pipe(
        catchError(
          error => {
            this.alertService.showAlert(error);
            throw error;
          }
        )
      );
  }

  /**
   * 作業を依頼する
   *
   * 戻り：REQUEST_RESPONSE
   * 対象：WEB ○ / APP ×
   *
   * @param { number } workID               作業ID
   * @param { REQUEST_POST } postdata       ポストデータ
   * @returns {Observable<any>}             結果
   * @throws { any }                        エラーメッセージ
   */
  requestWork(workID: number, postdata: REQUEST_POST): Observable<any> {
    return this.httpClient.post<any>(`${this.url}/workrequest/${workID}/request`, postdata)
      .pipe(
        catchError(
          error => {
            this.alertService.showAlert(error);
            throw error;
          }
        )
      );
  }

  /**
   * 作業依頼を承諾する
   *
   * 戻り：COMMON_RESPONSE
   * 対象：WEB ○ / APP ○
   *
   * @param { number } workID               作業ID
   * @returns {Observable<any>}             結果
   */
  acceptWork(workID: number): Observable<any> {
    return this.httpClient.post<any>(`${this.url}/workrequest/${workID}/accept`, null)
      .pipe(
        catchError(
          error => {
            this.alertService.showAlert(error);
            throw error;
          }
        )
      );
  }

  /**
   * 作業依頼を拒否する
   *
   * 戻り：COMMON_RESPONSE
   * 対象：WEB ○ / APP ○
   *
   * @param { number } workID             作業ID
   * @returns {Observable<any>}           結果
   */
  rejectWork(workID: number): Observable<any> {
    return this.httpClient.post<any>(`${this.url}/workrequest/${workID}/reject`, null)
      .pipe(
        catchError(
          error => {
            this.alertService.showAlert(error);
            throw error;
          }
        )
      );
  }

  /**
   * 作業を開始する
   *
   * 戻り：COMMON_RESPONSE
   * 対象：WEB × / APP ○
   *
   * @param { number } workID             作業ID
   * @param { GEO_POST } postdata         ポストデータ
   * @returns {Observable<any>}           結果
   */
  startWork(workID: number, postdata: GEO_POST): Observable<any> {
    return this.httpClient.post<any>(`${this.url}/workrequest/${workID}/start`, postdata)
      .pipe(
        catchError(
          error => {
            this.alertService.showAlert(error);
            throw error;
          }
        )
      );
  }

  /**
   * 作業を終了する
   *
   * 戻り：COMMON_RESPONSE
   * 対象：WEB × / APP ○
   *
   * @param { number } workID             作業ID
   * @param { GEO_POST } postdata         ポストデータ
   * @returns {Observable<any>}           結果
   */
  finishWork(workID: number, postdata: GEO_POST): Observable<any> {
    return this.httpClient.post<any>(`${this.url}/workrequest/${workID}/finish`, postdata)
      .pipe(
        catchError(
          error => {
            this.alertService.showAlert(error);
            throw error;
          }
        )
      );
  }
}
