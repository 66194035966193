export const environment = {
  production: true,

  apiUrl: 'https://api.pruuune.com/d0/',
  apiDomain: 'api.pruuune.com',

  //apiUrl: 'https://naweb.pic-dev.com',
  //apiDomain: 'naweb.pic-dev.com',
  googleMapsAPIKey: 'AIzaSyBigWFqdINznEZYLHmKTW7dWY4EcnSXiYM'
};
